import React from 'react';
// import "./style.css";
// import "./pmamcss.css";
// import { render } from "react-dom";
import { Router, Link } from "@reach/router";
// import { Helmet } from "react-helmet";
import TaxonomySingle from "./comp/TaxonomySingle";
import Page404 from "./comp/Page404";
import Page from "./comp/Page";
// import { get } from 'http';
// const process.env.REACT_APP_API = 'http://localhost:8888/newhorizon/process.env.REACT_APP_API/';
// const WS_PAGINATION = "strana";
// const WS_PAGINATION_COUNT = 10;
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBars, faSignOutAlt, faBell } from '@fortawesome/free-solid-svg-icons'
// import Calls from "./Calls";

class App extends React.Component {
  constructor(){
    super();
    this.state = {
      menu: {},
      collapse: true
    }
  }
  componentDidMount(){
    this.setState({ isLoading: true });
    fetch(process.env.REACT_APP_API + "menu/?position=menu-1", {credentials: "include"})
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ menu: data}))
      .catch(error => this.setState({ error }));
  }
  render(){
    // console.log(this.state.menu);
    let menu;
    if(this.state.menu.items!==undefined){
      console.log(this.state.menu.items[0].content);
      menu = Object.keys(this.state.menu.items).map((key) => {
        return(
          <Link id={"menu-item-"+this.state.menu.items[key].id} className="menu-item" key={"m-"+key} to={this.state.menu.items[key].content}>
            {this.state.menu.items[key].name}
          </Link>
        );
      });
    }
      return (
        <div className="App post-template-default single single-post postid-692 single-format-standard">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
              <Link rel="home" to="/" className="navbar-brand">
                Magazín
              </Link>
              <button className="navbar-toggler" onClick={() => (this.setState({collapse: !this.state.collapse}))}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={"navbar-collapse "+(this.state.collapse?"collapse":"")}>
                {menu}
              </div>
            </div>
          </nav>
          <Router>
            {/* <Home path="/" /> */}
            <TaxonomySingle path="/" />
            <Category path={process.env.REACT_APP_PAGINATION}>
              <CategoryIndex path="/" />
              <TaxonomySingle default type="index" />
            </Category>
            <Category path="kategorie">
              <CategoryIndex path="/" />
              <TaxonomySingle default type="cats" />
            </Category>
            <Category path="tag">
              <CategoryIndex path="/" />
              <TaxonomySingle default type="tags" />
            </Category>
            {/* <Page404 default /> */}
            <Page default />
        </Router>
        <footer className="bottom footer bg-dark text-secondary">
          <div className="container">
            Všechna práva vyhrazena magazín © 2019          
          </div>
        </footer>
        </div>
      );
  }
}

export default App;

// class Home extends React.Component {
//   constructor(){
//     super();
//     this.state = {
//       posts: [],
//       isLoading: true
//     }
//   }
//   componentDidMount() {
//     this.setState({ isLoading: true });
//     fetch(process.env.REACT_APP_API + "posts/")
//       .then(response => {
//         if (response.ok) {
//           return response.json();
//         } else {
//           throw new Error('Something went wrong ...');
//         }
//       })
//       .then(data => this.setState({ posts: data.posts, isLoading: false }))
//       .catch(error => this.setState({ error, isLoading: false }));
//   }
//   render(){
//     if(this.state.isLoading){
//       return(
//         <p>
//           loading
//         </p>
//       )
//     }else{
//       console.log(this.state.posts);
//       let posts = Object.keys(this.state.posts).map((key) => {
//         let date = new Date(this.state.posts[key].epochPublished*1000);
//         return(
//             <Post key={"post-"+key} name={this.state.posts[key].name} url={this.state.posts[key].fullslug} content={(this.state.posts[key].content?this.state.posts[key].content.replace(/<[^>]*>?/gm, ''):"")} tags={this.state.posts[key].tags} cats={this.state.posts[key].cats} date={date.toLocaleDateString("cs-CZ")} />
//         );
//       });
//       return(
//         <div>
//           <Helmet>
//             <title>Vítejte na NH</title>
//           </Helmet>
//           {posts}
//         </div>
//       )
//     }
//   }
// // }

// const Single = (props) => (
//  <main className="single">
//     <h1 className="title">
//       {props.name}
//     </h1>

//  </main> 
// );

const Category = (props) => (
  <div>
    {/* <h2>Kategorie</h2> */}
    {props.children}
  </div>
);

const CategoryIndex = () => (
  <Page404 />
)
