import React from 'react';
import { Link } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'

class Post extends React.PureComponent {
    render(){
        let tags;
        if(this.props.tags){
            let tagsArr = this.props.tags.split(", ");
            tags = Object.keys(tagsArr).map((key) => {
                return (
                    <Link className="taxonomy" key={"tag"+key} to={"/tag/"+tagsArr[key]}>
                        {tagsArr[key]}
                    </Link>
                );
            });
        }
        let img;
        if(this.props.img){
            img = <img alt="cover" className="attachment-medium-large size-medium-large wp-post-image" src={this.props.img.replace("thumbnails/", "").replace("_s.", ".")} srcSet={this.props.img.replace("thumbnails/", "").replace("_s.", ".")+" 1920w, "+this.props.img+" 150w, "+this.props.img.replace("_s.", "_m.")+" 768w, "+this.props.img.replace("_s.", "_l.")+" 1024w"} />
        }
      return(
        <div>
            <div className="container">
                <div className="row obsah-odsazeni single-intro-text">
                    <div className="col-md-12">
                        <div className="post type-post status-publish format-standard has-post-thumbnail hentry">
                            <h1 className="entry-title">
                                {this.props.name}
                            </h1>
                            <p className="muted">
                                <FontAwesomeIcon icon={faTag} /> {tags}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row obsah-odsazeni single-intro-img">
                {img}
            </div>
            <div className="container">
                <div className="row obsah-odsazeni">
                    <div className="col-md-2">

                    </div>
                    <div className="col-md-8">
                        <div className="entry-content" dangerouslySetInnerHTML={{__html: this.props.content}}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
  }
}

  export default Post