import React from 'react';
// import { Helmet } from "react-helmet";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'

class Post extends React.PureComponent {
    render(){
        // console.log(this.props);
        let tags, img;
        if(this.props.tags){
            let tagsArr = this.props.tags.split(", ");
            tags = Object.keys(tagsArr).map((key) => {
                return (
                    <Link className="taxonomy" key={"tag"+key} to={"/tag/"+tagsArr[key]}>
                        {tagsArr[key]}
                    </Link>
                );
            });
        }
        if(this.props.img){
            img = <Link to={(this.props.url?this.props.url:"#")} >
                    <img src={this.props.img} alt="náhledový obrázek" />
                </Link>
        }
      return(
        <div className={"post "+(img?"has-thumbnail":"has-no-thumbnail")+" type-post status-publish format-standard hentry"}>
            <div className="row">
                <div className="col-md-3 list-img-out">
                    {img}
                </div>
                <div className="col-md-9 list-text">
                    <div className="title entry-title">
                        <Link to={(this.props.url?this.props.url:"#")}>
                            <h3>
                                {this.props.name}
                            </h3>
                        </Link>
                    </div>
                    <div className="muted">
                        <span className={"taxonomy tax-"+(!this.props.tags?"hidden":"normal")}>
                            <FontAwesomeIcon icon={faTag} />
                            {tags}
                        </span>
                    </div>
                    <div className="content" dangerouslySetInnerHTML={{__html: this.props.content+"..."}}>
                    </div>
                </div>
            </div>
        </div>
      );
  }
}

  export default Post