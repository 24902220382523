import React from 'react';
import { Helmet } from "react-helmet";

const Page404 = React.memo(function MyComponent(props) {
    return (
        <div>
            <Helmet>
                <title>404</title>
            </Helmet>
                <h1>
                    404
                </h1>
            <p>
                Požadovaná stránka nebyla nalezena
            </p>
        </div>
    )
});

export default Page404