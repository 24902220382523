import React from 'react';
import { Helmet } from "react-helmet";
import Page404 from "./Page404";
import Post from "./Post";
import { Link } from "@reach/router";

class TaxonomySingle extends React.Component {
    constructor(){
      super();
      this.state = {
        posts: [],
        isLoading: true,
        status: undefined,
        heading: "",
        page: 1,
        count: 0,
        paginationCount: 0
      }
    }
    componentDidMount() {
      this.postsFetch();
    }
    componentDidUpdate(prevProps){
      if(this.props.uri!==prevProps.uri){
        this.postsFetch();
      }
    }
    postsFetch(){
      // console.log(this.props.uri);
      let uri = (this.props.uri.charAt(this.props.uri.length-1)==="/"?this.props.uri.substr(0, this.props.uri.length-1):this.props.uri);
      let uriArr = uri.split("/");
      // let start = uriArr.indexOf("kategorie");
      // let end = (uriArr.indexOf(process.env.REACT_APP_PAGINATION)>0?uriArr.indexOf(process.env.REACT_APP_PAGINATION):uriArr.length-1);
      let tax = "?";
      if(this.props.uri==="/"){
  
      }else if(uriArr.indexOf(process.env.REACT_APP_PAGINATION)>1||uriArr.indexOf(process.env.REACT_APP_PAGINATION)===-1){
        if(this.props.type==="tags"){
          for(var i=uriArr.indexOf("tag")+1;i<(uriArr.indexOf(process.env.REACT_APP_PAGINATION)>0?uriArr.indexOf(process.env.REACT_APP_PAGINATION):uriArr.length);i++){
            tax += "tags[]="+uriArr[i]+"&";
          }
        }else if(this.props.type==="cats"){
          //eslint-disable-next-line
          for(var i=uriArr.indexOf("kategorie")+1;i<(uriArr.indexOf(process.env.REACT_APP_PAGINATION)>0?uriArr.indexOf(process.env.REACT_APP_PAGINATION):uriArr.length);i++){
            tax += "cats[]="+uriArr[i]+"&";
          }
        }
      }
      // cats = cats.substr(0, cats.length-1);
      // const pageState = ;
      const page = "page="+(uriArr.indexOf(process.env.REACT_APP_PAGINATION)>0?uriArr[uriArr.indexOf(process.env.REACT_APP_PAGINATION)+1]:1);
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_API + "posts/"+tax+page)
        .then(response => {
          this.setState({status:response.status, isLoading: false});
          if (response.ok) {
            return response.json();
          }else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => this.setState({ posts: data.posts, count: data.count, isLoading: false, page: (uriArr.indexOf(process.env.REACT_APP_PAGINATION)>0?parseInt(uriArr[uriArr.indexOf(process.env.REACT_APP_PAGINATION)+1]):1), heading: data.heading, paginationCount: data.paginationCount }))
        .catch(error => this.setState({ err: error }));
    }
    render(){
        // console.log(this.state);
      if(this.state.isLoading){
        return(
          <p>
            loading
          </p>
        )
      }else if(this.state.status===404){
        return(
          <Page404 />
        )
      }else{
        let posts = Object.keys(this.state.posts).map((key) => {
          let date = new Date(this.state.posts[key].epochPublished*1000);
          return(
              <Post key={"post-"+key} name={this.state.posts[key].name} url={this.state.posts[key].fullslug} content={this.state.posts[key].content} tags={this.state.posts[key].tags} cats={this.state.posts[key].cats} date={date.toLocaleDateString("cs-CZ")} img={this.state.posts[key].img} />
          );
        });
        // console.log(posts);
        let a = this.props.uri.substr(0, this.props.uri.indexOf(process.env.REACT_APP_PAGINATION));
        const prevPage = (this.state.page!==1?<Link to={a+process.env.REACT_APP_PAGINATION+"/"+(this.state.page-1)} className="btn btn-lg btn-outline-primary">Předchozí strana</Link>:undefined);
        const nextPage = (this.state.page*this.state.paginationCount<this.state.count?<Link to={a+process.env.REACT_APP_PAGINATION+"/"+(this.state.page+1)} className="float-right btn btn-lg btn-outline-primary">Další strana</Link>:undefined);
        return(
          <div className="container">
            <h1>
              {this.state.heading}
            </h1>
            <div className="row">
              <div className="col-md-12">

              </div>
            </div>
            <Helmet>
              <title>{this.state.heading}</title>
            </Helmet>
            {posts}
            <div className="navigation">
              {prevPage}
              {nextPage}
            </div>
          </div>
        )
      }
    }
  }

  export default TaxonomySingle