import React from 'react';
import { Helmet } from "react-helmet";
import Page404 from "./Page404";
// import Post from "./Post";
import Single from "./Single";

class Page extends React.Component {
  constructor(){
    super();
    this.state = {
      post: [],
      status: undefined,
      isLoading: true
    }
  }
  componentDidMount() {
    this.pageFetch();
  }
  pageFetch(){
    // console.log(this.props.uri);
    this.setState({ isLoading: true });
    fetch(process.env.REACT_APP_API + "post/?fullslug="+this.props.uri, {method: 'get', credentials: 'include'})
      .then(response => {
        this.setState({status:response.status, isLoading: false});
        if (response.ok) {
          return response.json();
        }else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ post: data, isLoading: false }))
      .catch(error => this.setState({ err: error }));
  }
  componentDidUpdate(prevProps){
    if(prevProps.uri !== this.props.uri){
      this.pageFetch();
    }
  }
  render(){
    if(this.state.isLoading){
      return(
        <p>
          loading
        </p>
      )
    }else if(this.state.status===404){
      return(
        <Page404 />
      )
    }else{
      return(
        <div>
          <Helmet>
            <title>{this.state.post.name+" | Magazín"}</title>
          </Helmet>
          <Single name={this.state.post.name} tags={this.state.post.tags} img={this.state.post.img} content={this.state.post.content} />
          {/* <Post name={this.state.post.name} content={this.state.post.content} /> */}
        </div>
      )
    }
  }
}

export default Page